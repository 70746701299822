import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import Icon from './header/Icon';
import AppName from './header/AppName';
import LangaugeToggleButtons from './header/LangaugeToggleButtons';
import DayNightButton from './header/DayNightButton';
import UserButton from './header/UserButton';
import { useMediaQuery, useTheme } from '@mui/material';


const Header = ({darkMode, toggleDarkMode, user, setUser}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <AppBar position="static" color="primary">
        <Toolbar>
          {/* Icon */}
          <Icon />
          {/* App Name */}
          {isMobile ? null : <AppName />}
          {/* Language Toggle */}
          <LangaugeToggleButtons />
          {/* Day/Night Toggle */}
          <DayNightButton darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          {/* User Button */}
          <UserButton user={user} setUser={setUser} />
        </Toolbar>
      </AppBar>
  );
};

export default Header;
