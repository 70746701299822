import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Disclaimer = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <Box
      mt={3}
      p={1}
      bgcolor="background.default"
      borderRadius="10px"
      border="1px solid"
      borderColor="divider"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary" onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
          {t('disclaimerTitle')} {/* E.g., "Disclaimer" */}
        </Typography>
        <IconButton onClick={() => setOpen(!open)} size="small">
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
    
      <Collapse in={open}>
        <Typography variant="body2" color="textSecondary" mt={1}>
          {t('disclaimer')}
        </Typography>
      </Collapse>
    </Box>
  );
};

export default Disclaimer;
