import { useTranslation } from 'react-i18next';
import { FormControl, TextField } from '@mui/material';

const DishField = ({ dish, setDish }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth margin="normal">
      <TextField
        label={t('dishLabel')}
        variant="outlined"
        multiline
        rows={3}
        value={dish}
        onChange={(e) => setDish(e.target.value)}
        placeholder={t('dishPlaceholder')}
      />
    </FormControl>
  );
};

export default DishField;