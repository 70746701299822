import { useState } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { signOut } from 'aws-amplify/auth'; // Updated imports
import LoginModal from '../LoginModal'; // Ensure you have this component
import { useTranslation } from 'react-i18next';


const UserButton = ({user, setUser}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // Corrected this
  const { t } = useTranslation();

  const isLoggedIn = user !== null;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null); // Clear user state after sign out
      handleMenuClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleLoginModalOpen = () => {
    setIsLoginModalOpen(true);
    handleMenuClose();
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right', marginRight: 0 }}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleMenuOpen}
          aria-controls="user-menu"
          aria-haspopup="true"
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            >
            {isLoggedIn
                ? [
                    <MenuItem key="logout" onClick={handleSignOut}>
                        {t('signOut')}
                    </MenuItem>,
                ]
                : [
                    <MenuItem key="signin" onClick={handleLoginModalOpen}>
                        {t('signIn')}
                    </MenuItem>,
                ]}
        </Menu>
      </Box>

      {/* Login Modal */}
      <LoginModal open={isLoginModalOpen} onClose={handleLoginModalClose} setUser={setUser} />
    </>
  );
};

export default UserButton;
