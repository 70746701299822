import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';


const SubmitButton = ({ loading }) => {
  const { t } = useTranslation();

  return (
<Button
  type="submit"
  variant="contained"
  color="primary"
  fullWidth
  disabled={loading}
  sx={{
    mt: 2,
    fontWeight: 'bold',
    borderRadius: '20px',
    padding: '18px 0', // Increase padding for larger button
    // fontSize: '1.2rem', // Increase font size
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Subtle 3D shadow
    transition: 'transform 0.2s, box-shadow 0.2s', // Smooth hover effect
    '&:hover': {
      backgroundColor: 'primary.dark',
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)', // Stronger shadow on hover
      transform: 'translateY(-2px)', // Slight lift effect
    },
    '&:disabled': {
      backgroundColor: 'grey.400', // Disabled button background
      boxShadow: 'none', // Remove shadow for disabled button
    },
  }}
>
  {loading ? t('generatingButton') : t('generateButton')}
</Button>

  );
};

export default SubmitButton;