import React from 'react';
import { Link, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="body2">
        <Link href="/privacy" color="inherit" underline="always">{t('privacy')}</Link>&nbsp;|&nbsp;
        <Link href="/terms" color="inherit" underline="always">{t('terms')}</Link>
      </Typography>
    </Box>
  );
};

export default Footer;