import { useTranslation } from 'react-i18next';
import { Box, Chip } from '@mui/material';

const CommonRestrictions = ({ dietaryRestrictions, setDietaryRestrictions }) => {
  const { t } = useTranslation();

  const commonRestrictions = [
    'commonRestrictions.Gluten-Free',
    'commonRestrictions.Vegan',
    'commonRestrictions.Nut-Free',
    'commonRestrictions.Dairy-Free',
    'commonRestrictions.Vegetarian',
    'commonRestrictions.Keto',
  ];

  const toggleDietaryRestriction = (restriction) => {
    const restrictionsArray = dietaryRestrictions ? dietaryRestrictions.split(', ') : [];
    const translatedRestriction = t(restriction);
    if (restrictionsArray.includes(translatedRestriction)) {
      setDietaryRestrictions(restrictionsArray.filter((item) => item !== translatedRestriction).join(', '));
    } else {
      setDietaryRestrictions([...restrictionsArray, translatedRestriction].join(', '));
    }
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
      {commonRestrictions.map((restriction) => (
        <Chip
          key={restriction}
          label={t(restriction)}
          clickable
          onClick={() => toggleDietaryRestriction(restriction)}
          color={dietaryRestrictions.includes(t(restriction)) ? 'primary' : 'default'}
          variant={dietaryRestrictions.includes(t(restriction)) ? 'filled' : 'outlined'}
        />
      ))}
    </Box>
  );
};

export default CommonRestrictions;