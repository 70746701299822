import { useTranslation } from 'react-i18next';
import { FormControl, TextField } from '@mui/material';

const DietaryRestrictionsField = ({ dietaryRestrictions, setDietaryRestrictions }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth margin="normal">
      <TextField
        label={t('restrictionsLabel')}
        variant="outlined"
        multiline
        rows={2}
        value={dietaryRestrictions}
        onChange={(e) => setDietaryRestrictions(e.target.value)}
        placeholder={t('restrictionsPlaceholder')}
      />
    </FormControl>
  );
};

export default DietaryRestrictionsField;