import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router

const Privacy = () => {
  const { i18n } = useTranslation();
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const loadContent = async () => {
      const language = i18n.language || 'en';
      try {
        const response = await fetch(`/legal/privacy.${language}.md`);
        if (!response.ok) {
          throw new Error('Failed to fetch the privacy policy.');
        }
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error('Error loading privacy policy:', error);
        setContent('Sorry, the privacy policy could not be loaded.');
      }
    };
    loadContent();
  }, [i18n.language]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {i18n.t('privacy')}
      </Typography>
      <ReactMarkdown>{content}</ReactMarkdown>
      
      {/* Back to Main Page Button */}
      <Button 
        variant="contained" 
        color="primary" 
        sx={{ marginTop: 3 }}
        onClick={() => navigate('/')} // Replace '/' with the path to your main page
      >
        {i18n.t('back')} {/* Use a translation key for consistency */}
      </Button>
    </Container>
  );
};

export default Privacy;
