import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AppName = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      <span onClick={() => window.location.href = '/'} style={{ cursor: 'pointer' }}>
        {t('appName')}
      </span>
    </Typography>
  );
};

export default AppName;