import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { ThemeProvider, createTheme, CssBaseline, useMediaQuery } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import RecipeForm from './components/RecipeForm';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import { getCurrentUser } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import amplifyConfig from './config/aws-exports';
Amplify.configure(amplifyConfig);

// Optional: Configure the token provider
cognitoUserPoolsTokenProvider.setKeyValueStorage({
  getItem: (key) => localStorage.getItem(key),
  setItem: (key, value) => localStorage.setItem(key, value),
  removeItem: (key) => localStorage.removeItem(key),
});

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);
  const [user, setUser] = useState(null); // Manage user state

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: { main: darkMode ? '#90CAF9' : '#1976D2' }, // Blue
      secondary: { main: darkMode ? '#81C784' : '#388E3C' }, // Green
      error: { main: '#D32F2F' }, // Red for error messages
      warning: { main: '#FFA726' }, // Orange for warnings
      background: {
        default: darkMode ? '#121212' : '#F5F5F5',
        paper: darkMode ? '#1E1E1E' : '#FFFFFF',
      },
      text: {
        primary: darkMode ? '#FFFFFF' : '#000000',
        secondary: darkMode ? '#B0BEC5' : '#757575',
      },
    },
    typography: {
      h5: { fontWeight: 'bold', color: 'primary.main' }, // Bold headings
      body1: { lineHeight: 1.6 },
      button: { textTransform: 'none', fontSize: '1rem', fontWeight: 600 },
    },
  });
  

  // Fetch the current user on component mount
  useEffect(() => {
    const checkUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
      } catch (err) {
        setUser(null);
      }
    };

    checkUser();

    // New way to handle Hub events in v6
    const hubListener = Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      
      if (event === 'signedIn') {
        checkUser();
      } else if (event === 'signedOut') {
        setUser(null);
      }
    });

    // Cleanup function
    return () => {
      // In v6, the listener returns an unsubscribe function
      hubListener();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} user={user} setUser={setUser} />
        <main style={{ padding: '20px' }}>
          <Routes>
            <Route path="/" element={<RecipeForm user={user} setUser={setUser} />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<div>Page not found</div>} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
