import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en/translation.json';
import de from './locales/de/translation.json';

// Configure and initialize i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Define translation resources
    resources: {
      en: { translation: en },
      de: { translation: de }
    },
    
    // Language settings
    fallbackLng: 'en',
    supportedLngs: ['en', 'de'],
    
    // Language detection configuration 
    detection: {
      order: [
        'querystring',
        'cookie', 
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie']
    },

    // Interpolation settings
    interpolation: {
      escapeValue: false // Not needed since React handles escaping
    }
  });

export default i18n;