import React, { useState } from 'react';
import { Typography, Box, Modal, CardContent, CardMedia } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const RecipeDisplay = ({ recipe }) => {
  const [open, setOpen] = useState(false);

  // Toggle modal visibility
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Only render if recipe data exists
  if (!recipe) {
    return null;
  }

  return (
    <div>
      {/* Clickable Box with Recipe Title and Background */}
      <Box
        onClick={handleOpen}
        sx={{
          cursor: 'pointer',
          position: 'relative',
          padding: '20px',
          borderRadius: '20px',
          textAlign: 'center',
          marginTop: '20px',
          height: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundImage: `url(${recipe.imageURL})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white',
          '&:hover': {
            opacity: 0.9,
          },
        }}
      >
        {/* Overlay for better text visibility */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent overlay
            zIndex: 1,
          }}
        />

        {/* Recipe Title */}
        <Typography variant="h6" sx={{ fontWeight: 'bold', zIndex: 2 }}>
          {recipe.title}
        </Typography>
      </Box>

      {/* Modal Overlay for Full Recipe */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="recipe-modal-title"
        aria-describedby="recipe-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '90vh',
          }}
        >
          {/* Recipe Title */}
          <Typography id="recipe-modal-title" variant="h5" gutterBottom>
            {recipe.title}
          </Typography>
          {/* Recipe Image at the Top of the Modal */}
          {recipe.imageURL && (
            <CardMedia
              component="img"
              image={recipe.imageURL}
              alt={`${recipe.title} image`}
              sx={{
                borderRadius: '8px',
                mb: 2,
                width: '100%',
                height: 'auto',
                maxHeight: '300px', // Adjust the height as needed
                objectFit: 'cover',
              }}
            />
          )}

          {/* Recipe Content */}
          <CardContent>
            <ReactMarkdown>
              {recipe.instructions}
            </ReactMarkdown>
          </CardContent>
        </Box>
      </Modal>
    </div>
  );
};

export default RecipeDisplay;
