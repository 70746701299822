import React, { forwardRef } from 'react';
import { Modal, Box } from '@mui/material';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const LoginModal = forwardRef(({ open, onClose, setUser }, ref) => {
  const handleAuthSuccess = async (authData) => {
    try {
      if (authData) {
        setUser(authData);
        onClose();
      }
    } catch (err) {
      console.error('Auth success handling error:', err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="auth-modal"
    >
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#fff',
          p: 4,
          borderRadius: 2,
          width: '100%',
          maxWidth: '800px',
          height: '100%',
          maxHeight: '95vh',
          overflow: 'auto'
        }}
      >
        <Authenticator
          initialState="signIn"
          loginMechanisms={['email']}
          signUpAttributes={['email']}
          hideSignUp={false}
          variation="modal"
          components={{
            Header: () => null,
            Footer: () => null
          }}
        >
          {({ user }) => {
            if (user) {
              handleAuthSuccess(user);
              return null;
            }
            return null;
          }}
        </Authenticator>
      </Box>
    </Modal>
  );
});

LoginModal.displayName = 'LoginModal';

export default LoginModal;
