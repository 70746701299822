import { IconButton, Box } from '@mui/material';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';

const DayNightButton = ({ darkMode, toggleDarkMode }) => {  

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <IconButton onClick={toggleDarkMode} color="inherit">
        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
};

export default DayNightButton;