import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LangaugeToggleButtons = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0}}>
      <Button 
        color="inherit" 
        onClick={() => handleChangeLanguage('en')}
        sx={{ fontWeight: i18n.language === 'en' ? 'bold' : 'normal', textTransform: 'none' }}
      >
        En
      </Button>
      |
      <Button 
        color="inherit" 
        onClick={() => handleChangeLanguage('de')}
        sx={{ fontWeight: i18n.language === 'de' ? 'bold' : 'normal', textTransform: 'none' }}
      >
        De
      </Button>
    </Box>
  );
};

export default LangaugeToggleButtons;