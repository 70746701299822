import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import RecipeDisplay from './RecipeDisplay';
import { useTranslation } from 'react-i18next';
import LoginModal from './LoginModal'; // Import LoginModal
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import Title from './recipeform/Title';
import Disclaimer from './recipeform/Disclaimer';
import DishField from './recipeform/DishField';
import DietaryRestrictionsField from './recipeform/DietaryRestrictionsField';
import CommonRestrictions from './recipeform/CommonRestrictions';
import SubmitButton from './recipeform/SubmitButton';
import ErrorDisplay from './recipeform/ErrorDisplay';

const apiUrl = process.env.REACT_APP_API_URL;

const RecipeForm = () => {
  const [dish, setDish] = useState('');
  const [dietaryRestrictions, setDietaryRestrictions] = useState('');
  const [loading, setLoading] = useState(false);
  const [recipe, setRecipe] = useState(null);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  const generateRecipe = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      let session;
      try {
        session = await fetchAuthSession();
        if (!session?.tokens?.idToken) {
          setLoginModalOpen(true);
          return;
        }
      } catch (authError) {
        console.error('Auth error:', authError);
        setLoginModalOpen(true);
        return;
      }

      const token = session.tokens.idToken.toString();
      
      if (!dish.trim()) {
        throw new Error(t('error.noIngredients'));
      }

      const response = await axios.post(
        `${apiUrl}/api/recipes/generate`,
        {
          dish: dish,
          dietaryRestrictions,
          language: i18n.language,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setRecipe({
        title: response.data.title,
        instructions: response.data.instructions,
        imageURL: response.data.image_url,
      });
    } catch (error) {
      console.error('Recipe generation error:', error);
      setError(t('error.generic'));
    } finally {
      setLoading(false);
    }
  }, [dish, dietaryRestrictions, i18n.language, t]);

  const handleLoginSuccess = useCallback(async () => {
    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        setLoginModalOpen(false);
        if (dish.trim()) {
          generateRecipe();
        }
      }
    } catch (error) {
      console.error('Error getting current user:', error);
      setError(t('error.authenticationFailed'));
    }
  }, [dish, generateRecipe, t]);

  const handleSubmit = (event) => {
    event.preventDefault();
    generateRecipe();
  };

  return (
    <>
      <LoginModal 
        open={loginModalOpen} 
        onClose={() => setLoginModalOpen(false)}
        onSuccess={handleLoginSuccess}
      />
      <Box sx={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
        {/* Section 1: Form Title */}
        <Title />

        {/* Section 2: Form */}
        <Box sx={{ mb: 3 }}>  
          <form onSubmit={handleSubmit}>
            <DishField dish={dish} setDish={setDish} />

            <DietaryRestrictionsField dietaryRestrictions={dietaryRestrictions} setDietaryRestrictions={setDietaryRestrictions} />

            <CommonRestrictions dietaryRestrictions={dietaryRestrictions} setDietaryRestrictions={setDietaryRestrictions} />

            <SubmitButton loading={loading} />
          </form>
        </Box>

        {/* Section 3: Disclaimer */}
        <Disclaimer />

        <RecipeDisplay recipe={recipe} />

        {error && (
          <ErrorDisplay error={error} />
        )}
      </Box>
    </>
  );
};

export default RecipeForm;
